import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ContentAPI from 'api/content';

export default function Footer() {

  const [visitorIP, setVisitorIP] = useState('0.0.0.0');


  const getVisitorIP = async () => {
      const result = await ContentAPI.ip_address();
      if(result?.ip_address)
      {
        setVisitorIP(result?.ip_address);
      }
  }

  useEffect(() => {  
    getVisitorIP();
  }, []);

  return (
    <footer className="">
      <p className="text-center footer-txt">BDO Customer Contact Center: (02) 8631-8000<br />
        BDO Unibank, Inc. is regulated by the Bangko Sentral ng Pilipinas:<br /> Tel. No. (02) 8708-7087; Email:
        consumeraffairs@bsp.gov.ph; Webchat: bsp.gov.ph<br />
        The BDO, BDO Unibank and other BDO-related trademarks are owned by BDO Unibank, Inc.
        <div className="visitor-ip" style={{color: "#b6b6b691"}}>{ visitorIP }</div>
      </p>
    </footer>
  );
}
