import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-bootstrap';
import OtpInput from 'react-otp-input';

import OtpAPI from 'api/otp';
import ContentAPI from 'api/content';
import Timer from './Timer';
import { AuthContext, BEARER_TOKEN_KEY } from 'auth/Auth';
import { DefaultToast, InlineSpinner } from 'components/util/ui';
import AlertModal from 'components/modals/AlertModal';
import OTPIcon from 'assets/images/otp-icon.png';
import OTPInfoIcon from 'assets/images/Icon-Information.svg';
import './otp.css';
interface OtpProps {
  resendInterval: number,
  serverEpoch?: number // For syncing otp timer
  referenceNumber: string
}
interface OtpSettingState {
  otp_expiration: number,
  otp_interval: number,
  otp_maxattempts: number,
  otp_maxresend: number,
  otp_templockduration: number
}

interface AlertModalContent {
  show_icon: boolean,
  title: string,
  content:string
}


enum ErrorState {
  NONE,
  EXPIRED,
  MAX_ATTEMPTS_REACHED,
  WRONG_OTP,
  LOW_CREDIT
}

// const TRANSLATIONS = {
//   [ErrorState.NONE]: '',
//   [ErrorState.EXPIRED]: 'Your OTP expired. Request a new OTP by tapping Resend OTP',
//   [ErrorState.MAX_ATTEMPTS_REACHED]: "You have reached the maximum numbers of attempts. Kindly try again in 24 hours.",
//   [ErrorState.WRONG_OTP]: 'Invalid OTP. Double check and try again',
//   [ErrorState.LOW_CREDIT]: 'Your available Credit Limit is below the minimum allowable Cash Availment amount of P10,000. You may re-apply once you have sufficient Credit Limit.',
// }

var TRANSLATIONS = {
  [ErrorState.NONE]: {
    header: '',
    message: ''
  },
  [ErrorState.EXPIRED]: {
    header: '',
    message: 'OTP expired. Request a new OTP by clicking Resend OTP'
  },
  [ErrorState.MAX_ATTEMPTS_REACHED]: {
    header: '',
    message:  "You have reached the maximum numbers of attempts. Kindly try again in 24 hours.",
  },
  [ErrorState.WRONG_OTP]: {
    header: '',
    message: 'Invalid OTP. Double check and try again',
  },
  [ErrorState.LOW_CREDIT]: {
    header: '',
    message: 'Your available Credit Limit is below the minimum allowable Cash Availment amount of P10,000. You may re-apply once you have sufficient Credit Limit.',
  },
}

export default function OTP(props: OtpProps) {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [otp, setOtp] = useState('');
  const [errorState, setErrorState] = useState<ErrorState>(ErrorState.NONE)

  const [recipient, setRecipient] = useState('xxxx');

  const [otpSent, setOtpSent] = useState(false);
  const [showCountdown, setShowCountdown] = useState(true);

  const [AlertModalContent, setAlertModalContent] = useState<AlertModalContent>({
    show_icon: false,
    title: '',
    content: '',
  });

  const [submitting, setSubmitting] = useState(false);
  const [resending, setResending] = useState(false);
  const [otpRequestCount, setOtpRequestCount] = useState(0);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [OtpExpiration, setOtpExpiration] = useState(1);
  const [AOtpSettings, setAOtpSettings] = useState<OtpSettingState>(
    {
      otp_expiration: 5,
      otp_interval: 3,
      otp_maxattempts: 1,
      otp_maxresend: 1,
      otp_templockduration: 1
    }
  );

  var otp_send_count = 0;


  const loadOtpSettings = async () => {
      // Load Settings
      const result = await OtpAPI.all_otp_settings();
      setAOtpSettings({
        otp_expiration: result.frontend_otp_expiration,
        otp_interval: result.frontend_otp_interval,
        otp_maxattempts: result.frontend_otp_maxattempts,
        otp_maxresend: result.frontend_otp_maxresend,
        otp_templockduration: result.frontend_otp_templockduration
      })
   
  }

  const loadErrorMessages = async () => {
    const result = await ContentAPI.error_messages();
    if(result.data)
    {
      var error_list = result.data;
      error_list.forEach((error:any)=>{
          if( (error.code).toLowerCase() == 'insufficient_credit_limit')
          {
            TRANSLATIONS[ErrorState.LOW_CREDIT].header = error.title;
            TRANSLATIONS[ErrorState.LOW_CREDIT].message = error.spiel;
          }
          else if((error.code).toLowerCase() == 'otp_invalid_max_attempt')
          {
            TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].header = error.title;
            TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].message = error.spiel;
          }
      });
    }

  };

  const hideCustomModal = () => {
    setShowCustomModal(false);
    if(errorState == ErrorState.LOW_CREDIT || errorState == ErrorState.MAX_ATTEMPTS_REACHED)
    {
      navigate('/start', { replace: true })
    }
  }
  const showModal = () => setShowCustomModal(true);

  useEffect(() => {
    loadErrorMessages();
    loadOtpSettings();
    sendOtp();
  }, [])


  const sendOtp = async () => {

    setAlertModalContent({
      show_icon: false,
      title: '',
      content: ''
    });
    setErrorState(ErrorState.NONE)
    setOtpRequestCount(otpRequestCount + 1);
    setShowCountdown(false);


    try {
       const result = await OtpAPI.generate(props.referenceNumber);
     
        if (result.status) {
          setOtp('');
          setRecipient(result.recipient)
          setOtpSent(true)
          setShowCountdown(true)
          setResending(false)

          // Restart Session
           sessionStorage.removeItem('la');
           sessionStorage.removeItem('t');
           sessionStorage.removeItem('bdoca_sess');
           sessionStorage.removeItem('bdoca_lo');

            sessionStorage.setItem('bdoca_sess', JSON.stringify({
              refno: props.referenceNumber,
              session_token: result.session_token,
              expires_at: result.expires_at
            }));
            //

        } else {
          setShowCountdown(false)
          setAlertModalContent({
            show_icon: false,
            title: '',
            content: (result.remarks)
          });

          if(result.remarks == "You've reached the maximum attempts, Please try again later.")
          {
            console.log("Request OTP: Max OTP Request Reached");
            setErrorState(ErrorState.MAX_ATTEMPTS_REACHED)
            setShowCountdown(false)
            setOtpRequestCount(AOtpSettings.otp_maxresend)
            setAlertModalContent({
              show_icon: false,
              title: TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].header,
              content: TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].message
            });
          }
          setResending(false)
          showModal()
        }
    }
    catch(err) {
      console.log("Request OTP: Connection Error");
        setShowCountdown(false)
          setAlertModalContent({
            show_icon: false,
            title: '',
            content: "There's a problem connecting to server. Please try again later"
          });

          setResending(false)
          showModal()
    }
   
  }

  const resendOtp = async () => {
    setResending(true);
    sendOtp();
  }

  const verifyOtp = async () => {
    setSubmitting(true);

    let session = sessionStorage.getItem('bdoca_sess');
    if(session)
    {
      let session_data = JSON.parse(session);

      const result = await OtpAPI.authenticate(props.referenceNumber, otp, session_data?.session_token);
      if (result.status) {
        auth.setBearerToken(result.bearerToken);
        setSubmitting(false);
        navigate('/offer', { replace: true })
      }
      else
      {
        // Has error
        if (result.code === 'max_attempts_reached' || result.attemptsLeft === 0) {
          console.log("Verify OTP: Max OTP Attempts Reached");
          setErrorState(ErrorState.MAX_ATTEMPTS_REACHED)
          setShowCountdown(false)
          setOtpRequestCount(AOtpSettings.otp_maxresend)
          setAlertModalContent({
            show_icon: false,
            title: TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].header,
            content: TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].message
          });
           showModal()
          setSubmitting(false);
        }
        else if (result.code === 'max_limit' || (result.remarks).toLowerCase() == 'max limit') {
          console.log("Verify OTP: Low Credit");
          setErrorState(ErrorState.LOW_CREDIT)
          setShowCountdown(false)
          setOtpRequestCount(AOtpSettings.otp_maxresend)
            setAlertModalContent({
              show_icon: true,
              title: TRANSLATIONS[ErrorState.LOW_CREDIT].header,
              content: TRANSLATIONS[ErrorState.LOW_CREDIT].message
            });
           showModal();
          setSubmitting(false);
        }
        else if (result.code === 'expired_otp' || (result.remarks).toLowerCase() == 'otp is expired') {
          console.log("Verify OTP: OTP Expired");
          setErrorState(ErrorState.EXPIRED)
          setShowCountdown(false)
          setSubmitting(false);
        }
         else if ((result.remarks).toLowerCase() == 'invalid token') {
          console.log("Verify OTP: Session Token not found");
          navigate('/start?error=session_expired');
        }
         else {
          console.log("Verify OTP: Invalid OTP");
          setErrorState(ErrorState.WRONG_OTP)
          setShowCountdown(false)
          setSubmitting(false);
        }
      }
    }
    else
    {
      console.log("Verify OTP: Session Token not found");
      navigate('/start?error=session_expired');
    }

  }

  // OTP state is unrecoverable?

  const isTerminal = () => {
    return [ErrorState.EXPIRED, ErrorState.MAX_ATTEMPTS_REACHED].indexOf(errorState) !== -1
  }

  // TODO: Provide loader
  return (
    <div className="container container-m py-3">
      <div className="otp default-box-shadow w-bg">

        <ToastContainer className="p-3 mt-5" position="top-end">
          <DefaultToast visibleState={[otpSent, setOtpSent]} bg="primary">Your OTP was successfully sent</DefaultToast>
        </ToastContainer>

        <div className="card border-0" >
          <p className="card-title">We need to make sure it's you</p>

          <div className="card-body text-center">
            <div className="otp-icon">
              <img src={OTPIcon} className="img-fluid rounded-start" alt="OTP Icon" />
            </div>

            <p className="card-text">Please enter the OTP (One-Time PIN) we sent to your registered<br /> mobile number (ending in {recipient}).<br /> Do not share your OTP with anyone else.</p>
            {/* <p className="card-text">Ref No. {props.referenceNumber}</p> */}

            <OtpInput numInputs={6}
              inputStyle="form-control otp-input"
              isInputNum={true}
              shouldAutoFocus={true}
              value={otp}
              onChange={setOtp}
              hasErrored={errorState !== ErrorState.NONE}
              errorStyle="invalid-otp"
              containerStyle="otp-flex"
            />

            {(errorState !== ErrorState.NONE) && <div className="text-center p-2">
              <span className="text-danger">{TRANSLATIONS[errorState].message} </span>
            </div>}

            <p className="text-center mt-3">
              {!isTerminal() && showCountdown && <button
                disabled={showCountdown || resending}
                className="btn btn-link txt-decor-none"
              >
                <span className='info-img'><img src={OTPInfoIcon} className="img-fluid rounded-start" alt="OTP Info Icon" /></span>
                <span className="otp-valid">
                  { resending ? 'Please wait...' : 'OTP is only valid for ' + AOtpSettings.otp_expiration + ' minutes'} <span className='bold'>{''}</span>

                  ({showCountdown && <b><Timer startAt={props.resendInterval} onFinish={() => setShowCountdown(false)} /></b>})
                </span>
                <br />
                {/*<a onClick={ showCountdown ? undefined : resendOtp} >Resend OTP</a>*/}
              </button>}
            </p>

            <AlertModal
            className="alert-modal"
              backdrop="static"
              keyboard={false}
              size="lg"
              fullscreen="sm-down"
              scrollable={true}
              show={showCustomModal}
              onClose={hideCustomModal}
              content={AlertModalContent}
              centered
            />
            <div className="d-grid col-6 mx-auto mt-3 sm-mt-3 px-2 sm-px-2">
              <button disabled={ showCountdown || otpRequestCount >= AOtpSettings.otp_maxresend } className="btn btn-link txt-decor-none resend-otp" onClick={ resendOtp } >
                 <a>Resend OTP</a>
              </button>

              <button
                disabled={otp.length !== 6 || submitting || isTerminal()}
                className="btn btn-primary"
                onClick={verifyOtp}
              >
                {submitting ?
                  <>
                    Please wait... <InlineSpinner />
                  </>
                  :
                  "Submit"
                }
              </button>

       
            </div>
          </div>



        </div>
      </div>
    </div>
  )
}
