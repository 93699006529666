import React, { createContext, useState } from 'react';
import { Navigate } from 'react-router-dom'
import OtpAPI from 'api/otp';
export interface AuthContextType {
  // The user's token after a succesful OTP authentication.
  // We will need this for endpoint authorization (X-Authorization: Bearer <token>)
  bearerToken: string,
  setBearerToken: (bearer: string) => void,
}
interface OtpSettingsType {
  setting_value: number,
}
export let DEFAULT_OTP_INTERVAL = 60*3; // seconds
export const BEARER_TOKEN_KEY = 't'; // Index in session storage
export const AuthContext = createContext<AuthContextType>(null!);

var setOtpInterval = Promise.resolve(OtpAPI.all_otp_settings());
setOtpInterval.then(function(v) {
  DEFAULT_OTP_INTERVAL = 60*v.frontend_otp_expiration;
}, function(e) {
});

export function AuthProvider({ children } : { children: React.ReactNode }) {
  const [ bearerToken, setBearerToken ] = useState('');

  const value = {
    bearerToken,
    setBearerToken: (token: string) => {
      setBearerToken(token)
      sessionStorage.setItem(BEARER_TOKEN_KEY, token)
    }
    
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function RequireAuth(props: { children: JSX.Element }) {
  const auth = React.useContext(AuthContext)
  const bearer = auth.bearerToken || sessionStorage.getItem(BEARER_TOKEN_KEY);

  if (!bearer /* || sessionStorage or expired */) {
    return <Navigate to="/start" />
  }

  return props.children;
}
